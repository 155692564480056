import { NgModule } from '@angular/core'
import { addNavMenuItem, SharedModule } from '@vendure/admin-ui/core'

@NgModule({
  imports: [SharedModule],
  providers: [
    addNavMenuItem(
      {
        id: 'badges',
        label: 'Badges',
        routerLink: ['/extensions/badges'],
        icon: 'star',
      },
      'catalog',
    ),
  ],
})
export class BadgesNavModule {}
